<template>
  <div class="content-wrapper view-setting pt-2">
    <div ref="fileManageContent" class="content"></div>
    <b-card>
      <b-row class="px-3">
        <b-col cols="6">
          <h4 inline>
            <i class="fas fa-file"></i> จัดการไฟล์
            <b-button
              id="view-file-storage-info-button"
              class="mb-2 mx-2"
              size="sm"
            >
              <i class="fas fa-info"></i>
            </b-button>
          </h4>

          <b-popover target="view-file-storage-info-button" triggers="hover">
            <template #title> File Storage </template>
            <b-row>
              <b-col> <strong>วันหมดอายุ:</strong></b-col>
              <b-col> {{ fileStoreExpiration }} </b-col>
            </b-row>
            <b-row>
              <b-col><strong> ข้อมูลที่เก็บได้:</strong></b-col>
              <b-col> {{ fileStoreQuota }}</b-col>
            </b-row>
            <b-row>
              <b-col><strong> ข้อมูลที่ใช้:</strong></b-col>
              <b-col> {{ fileStoreUsage }}</b-col>
            </b-row>
          </b-popover>
        </b-col>

        <b-col>
          <b-form-group
            class="ml-auto"
            label-cols="4"
            label="เรียงโดย:"
            label-align="right"
          >
            <b-form-select
              :options="sortList"
              value-field="value"
              text-field="label"
              v-model="sortBy"
              size="sm"
              @change="fetchFiles"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-cols="4" label="เรียงจาก:" label-align="right">
            <b-form-select
              :options="orderList"
              value-field="value"
              text-field="label"
              v-model="order"
              size="sm"
              @change="fetchFiles"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        ref="fileManageTable"
        striped
        hover
        :fields="fields"
        :items="getFileList"
      >
        <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
        <template v-slot:cell(src)="row">
          <div @click="handleThumbnailClick(row.index)">
            <div
              v-if="row.item.fileType === 'application/pdf'"
              class="text-center"
            >
              <i class="far fa-file-pdf" style="font-size: 40px"></i>
            </div>
            <b-img-lazy v-else v-bind="imgProps" :src="row.value"></b-img-lazy>
          </div>
        </template>
        <template v-slot:cell(button)="row">
          <div v-if="row.item.isDeleting" class="d-flex justify-content-center">
            <b-spinner
              class="mt-2"
              variant="danger"
              label="Deleting..."
            ></b-spinner>
          </div>
          <b-button
            v-else
            class="show-when-hovered"
            size="xs"
            variant="danger"
            @click="deleteFile(row.item)"
            ><i class="fas fa-trash-alt"></i
          ></b-button>
        </template>
        <template v-slot:custom-foot v-if="isTableLoading">
          <b-tr>
            <b-td colspan="12" class="text-center mt-2">
              <b-spinner variant="primary"></b-spinner>
            </b-td>
          </b-tr> </template></b-table
    ></b-card>
    <Lightbox :index="lightBoxIndex" :items="lightBoxList" @close="resetList" />
    <Dialog ref="Dialog"></Dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Dialog from "@/components/modal/Dialog";

import VueLightbox from "vue-lightbox-lite";
import "vue-lightbox-lite/dist/index.css";

import bytesToSize from "@/utils/bytesToSize";
import debounce from "@/utils/debounce";

import moment from "moment";
export default {
  name: "FileMange",
  components: { Lightbox: VueLightbox, Dialog },
  data() {
    return {
      fileList: [],
      fields: [
        {
          key: "index",
          label: "",
          class: "text-center",
        },
        {
          key: "creationDt",
          label: "วันที่สร้าง",
          formatter: "formatDateTime",
        },
        {
          key: "fileSize",
          label: "size",
          formatter: bytesToSize,
        },
        {
          key: "fileType",
          label: "ประเภท",
          sortable: true,
        },
        {
          key: "src",
          label: "",
        },
        {
          key: "remark",
          label: "รายละเอียด",
        },
        {
          key: "button",
          label: "",
        },
      ],
      offset: 0,
      limit: 10,

      sortList: [
        {
          label: "ขนาดไฟล์",
          value: "fileSize",
        },
        {
          label: "วันที่สร้าง",
          value: "creationDt",
        },
      ],
      orderList: [
        {
          label: "มากไปน้อย",
          value: "desc",
        },
        {
          label: "น้อยไปมาก",
          value: "asc",
        },
      ],
      sortBy: "creationDt",
      order: "desc",
      imgProps: {
        blank: true,

        height: 100,
        class: "m1",
      },
      debouncedHandleScroll: null,
      isTableLoading: false,
      fetchFilesMax: false,

      lightBoxIndex: null,
      lightBoxList: [],

      fileStoreExpiration: "",
      fileStoreQuota: "",
      fileStoreUsage: "",
    };
  },
  create() {},
  computed: {
    ...mapGetters({
      getFileList: "moduleMediaFile/getFileList",
      getBranchInfo: "moduleUser/getBranchInfo",
    }),
  },
  methods: {
    ...mapActions({
      fecthMediaFiles: "moduleMediaFile/fecthMediaFiles",
      deleteMediaFile: "moduleMediaFile/deleteMediaFile",
    }),
    async fetchFiles() {
      const params = {
        offset: this.offset,
        limit: this.limit,
        sort: this.sortBy,
        order: this.order,
      };
      this.fileList = await this.fecthMediaFiles({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        params,
      });
    },
    async fetchScrollFiles() {
      try {
        this.isTableLoading = true;
        const params = {
          offset: this.offset,
          limit: this.limit,
          sort: this.sortBy,
          order: this.order,
          concat: true,
        };
        const list = await this.fecthMediaFiles({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          params,
        });
        if (list.length === 0) this.fetchFilesMax = true;
        this.fileList = [...this.fileList, ...list];
      } catch (err) {
        console.error(err);
      } finally {
        this.isTableLoading = false;
      }
    },
    formatDateTime(date) {
      return moment(date).locale("th").format("L LT");
    },
    handleScroll(e) {
      if (this.isTableLoading || this.fetchFilesMax) return;
      let docEl = e.target.documentElement;

      if (docEl.scrollTop + docEl.clientHeight >= docEl.scrollHeight - 1) {
        this.offset += this.limit;
        this.fetchScrollFiles();
      }
    },
    handleThumbnailClick(index) {
      this.lightBoxIndex = index;
      this.lightBoxList = [...this.getFileList];
    },
    resetList() {
      this.lightBoxIndex = null;
      this.lightBoxList = [];
    },
    async deleteFile(item) {
      try {
        const { isConfirmed } = await this.$refs.Dialog.showAlertConfirm(
          "ลบไฟล์นี้ ?",
          `คุณต้องการลบไฟล์นี้`
        );
        if (isConfirmed) {
          this.$set(item, "isDeleting", true);
          const res = await this.deleteMediaFile({
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            id: item.id,
          });
          if (res.status === 200) {
            this.fileList = this.fileList.filter(i => {
              return item.id !== i.id;
            });
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.$set(item, "isDeleting", false);
      }
    },
  },
  async mounted() {
    this.fileStoreExpiration = this.getBranchInfo.fileStoreExpiration
      ? moment(this.getBranchInfo.fileStoreExpiration).locale("th").format("LL")
      : "";
    this.fileStoreQuota = bytesToSize(this.getBranchInfo.fileStoreQuota);
    this.fileStoreUsage = bytesToSize(this.getBranchInfo.fileStoreUsage);

    this.fetchFiles();
    this.debouncedHandleScroll = debounce(this.handleScroll, 500);
    window.addEventListener("scroll", this.debouncedHandleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.debouncedHandleScroll);
  },
};
</script>
